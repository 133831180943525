<template>
    <div>
    <b-card>
        <b-form @submit="onSubmit">
            <b-row>
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Batch Name:">
                    <b-form-input v-model="form.batch_name" type="text" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Client:">
                    <b-form-input v-model="form.client" type="text" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Location:">
                    <b-form-input v-model="form.location" type="text" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Start Date:">
                    <b-form-input v-model="form.start_date" type="date" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="End Date:">
                    <b-form-input v-model="form.end_date" type="date" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Trainer Name:">
                    <b-form-input v-model="form.trainer" type="text" required></b-form-input>
                </b-form-group> </b-col> 
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Manager Name:">
                    <b-form-input v-model="form.manager" type="text" required></b-form-input>
                </b-form-group> </b-col>
                <b-col md="4" xl="4" xs="6" class="mb-1"> <b-form-group label="Client Name:">
                    <b-form-input v-model="form.client_name" type="text" required></b-form-input>
                </b-form-group> </b-col> 
            </b-row>
            <b-button type="submit" variant="primary">Submit</b-button>
            
        </b-form>
    </b-card>
    </div>
  </template>
  <script>
  import {
  
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox, BFormCheckboxGroup,BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormFile,
    BFormTextarea,
    BButton, BSidebar, 
  } from "bootstrap-vue";
  import TrainingCandidateService from '@/services/training-candidate-service'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import TrainingBatchService from '@/services/training-batch-service'
  
  export default {
    components: {
      BSidebar, BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox, BFormCheckboxGroup,BFormDatepicker,
      BFormInvalidFeedback,
      BCard,
      BRow,
      BCol,
      BFormFile,
      BFormTextarea,
      // Form Validation
      
    },
    
    data() {
      return {
          form: {
            batch_name: '',
            //client_name: '',
            client: '',
            location: '',
            start_date: '',
            end_date: '',
            trainer: '',
            manager: '',
            client_name: ''
          },
        errors: {},
        error: '',
        batch_id: ''
      }      
    },
    
    created() {
      this.batch_id = this.$route.params.id;
    },
    methods: {
      
      onSubmit() {
        
        // No errors, submit the form
        // Use axios or any other method to submit the data to the server
        TrainingBatchService.createTrainingBatch(this.form).then( res => {
            console.log(res, this.form)
            if(res.status === 200) {
            console.log(res.data)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Training Batch Add Successful',
                    icon: 'EditIcon',
                    variant: 'success',
                },
            })
            //this.$router.push({ path: '/apps/train-n-hire'})
            } else if (res.status === 404) {
            //this.batches = []
            //this.error = "No Batches added in the system yet"
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Batch Add Failed',
                    icon: 'EditIcon',
                    variant: 'danger',
                },
            })
            } else if (res.status === 500) {
            //this.batches = []
            //this.error = "Some error occurred, error meesage: " + res.message + "    Error: " + res.error
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Batch Add Failed',
                    icon: 'EditIcon',
                    variant: 'danger',
                },
            })
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            //this.batches = []
            this.error = "Session Expired... Redirecting to Login Page"
            
            localStorage.clear();
                this.$router.push({ name: "auth-login" });
            }
        })
          
      }
    
  }
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-candidate-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  .b-sidebar.sidebar-lg {
    width: 45rem;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hidden{
    display: none;
  }
  </style>